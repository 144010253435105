<!-- eslint-disable max-lines -->
<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="d-flex flex-column overflow-y-hidden"
		style="width: 100%;"
	>
		<ConsentCollectionLayout
			v-if="displayPage"
			:title="`${isEdit ? 'Edit' : 'Create'} Drag-and-Drop Preference Page`"
			:caption="stepCaption"
		>
			<template #header-after>
				<Stepper
					:steps="steps"
					:step="step"
					:edit-mode="isEdit"
					@update:step="step = $event"
				/>
			</template>
			<template #content>
				<StepperItems
					:steps="steps"
					:step="step"
				>
					<template #0>
						<ConsentCollectionDetails
							:details.sync="details"
							:is-edit="isEdit"
							:user-full-permissions="userFullPermissions"
							:is-partial-or-complete="isPartialOrCompletePreferencePage"
							parent-type="Preference Page"
							:languages="lookupData.languages"
							@update-details="onUpdateDetails"
						/>
					</template>
					<template #1>
						<BuildStep
							v-if="displayBuilder"
							:channels="channels"
							:headers="headers"
							:footers="footers"
							:brand-id="preferencePage.brandId"
							:extended-preferences="extendedPreferences"
							:user-full-permissions="userFullPermissions"
							type="preferencePage"
						/>
					</template>
					<template #2>
						<ThemeStep
							v-if="displayBuilder"
							:user-full-permissions="userFullPermissions"
							:all-themes="themes"
							:channels="channels"
							:extended-preferences="extendedPreferences"
							:brand-id="preferencePage.brandId"
							:create-style-mode="!preferencePage.formBuilderThemeGUID"
							type="preferencePage"
						/>
					</template>
					<template #3>
						<ConfigurePreferencePage
							:preference-page.sync="preferencePage"
							:user-full-permissions="userFullPermissions"
							:prove-confirmation-emails="lookupData.proveConfirmationEmails"
							:standdak-fields="lookupData.availableFields"
							:encryption-keys="lookupData.encryptionKeys"
							:languages="lookupData.languages"
							:form-has-pii-fields="formHasPiiFields"
							:is-edit="isEdit"
							@reload-lookup-data="onReloadLookupData"
						/>
					</template>
					<template #4>
						<OutcomesStep
							:preference-page.sync="preferencePage"
							:user-full-permissions="userFullPermissions"
							:confirmation-pages="lookupData.confirmationPages"
							:assigned-languages="lookupData.languages"
						/>
					</template>
					<template #5>
						<DeployPreferencePage
							:show-preview-button="showPreviewButton"
							:embed-code="embedCode"
							:preference-page-id="prefPageId"
							:assigned-languages="lookupData.languages"
						/>
					</template>
				</StepperItems>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton
							test-id="manage_preference_page_back_to_overview_button"
							@click="backToOverview"
						>
							Back To {{ accessedViaHub ? 'Consent Collection' : 'Overview' }}
						</SecondaryActionButton>
						<v-spacer />
						<SecondaryActionButton
							v-if="step > 0"
							test-id="manage_preference_page_previous_button"
							@click="handleSubmit(previousStep)"
						>
							Previous
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="step < steps.length - 1 && isEdit && userFullPermissions"
							class="ml-2"
							test-id="manage_preference_page_save_button"
							@click="handleSubmit(savePreferencePage)"
						>
							Save Changes
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="!isEdit && userFullPermissions && step !== steps.length - 1 && step !== steps.length - 2"
							class="ml-2"
							test-id="manage_preference_page_save_progress_button"
							@click="handleSubmit(savePreferencePage)"
						>
							Save
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="step === steps.length - 2 && !isEdit && userFullPermissions"
							class="ml-2"
							test-id="manage_preference_page_finish_button"
							@click="handleSubmit(nextStep)"
						>
							Finish
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="showNextButton"
							test-id="manage_preference_page_next_button"
							class="ml-2"
							@click="handleSubmit(nextStep)"
						>
							Next
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ConsentCollectionLayout>
		<ConfirmThemeManageModal
			v-if="themeToManage"
			:theme-to-manage="themeToManage"
			@close="themeToManage = null"
			@manage-theme="manageTheme"
		/>
		<NonFormUnsavedChangesModal
			v-if="showUnsavedChangesModal"
			:next="nextFunction"
			@cancel="showUnsavedChangesModal = false"
			@proceed="proceedFromUnsavedChanges"
		/>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import Stepper from '../../../../../../shared/components/stepper-v2.vue'
import StepperItems from '../../../../../../shared/components/stepper-v2-items.vue'
import ConsentCollectionDetails from '../consent-collection-details-card.vue'
import BuildStep from '../build-step.vue'
import ThemeStep from '../theme-step.vue'
import ConsentCollectionLayout from '../consent-collection-layout.vue'
import ConfigurePreferencePage from './configure-page/configure-preference-page.vue'
import DeployPreferencePage from './deploy-step/deploy-preference-page.vue'
import OutcomesStep from './outcomes-step/outcomes-step-view.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import ConfirmThemeManageModal from '../confirm-theme-manage-modal.vue'
import NonFormUnsavedChangesModal from '../../../../../../shared/components/non-form-unsaved-changes-modal.vue'
import { CONSENT_COLLECTION_PREFERENCE_PAGES, CONSENT_COLLECTION } from '../../../../router/route-names.js'
import { getLinks } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { getPreferencePage, createPreferencePage, updatePreferencePage } from '../../../../../../shared/utils/api/preference-pages.js'
import {
	getSchema,
	getTheme,
	getAllThemes,
	postSchema,
	putSchema
} from '../../../../../../shared/utils/api/form-builder.js'
import { getPreferenceChannels } from '../../../../../../shared/utils/api/channels.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../shared/enums/headers-and-footers.js'
import { getFooters, getHeaders } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { getExtendedPreferences } from '../../../../../../shared/utils/api/extended-preferences.js'
import { LINKS_AREA_FULL_PERMISSIONS,	LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { defaultBrandInSelect, brandOptions } from '../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { getEnv } from '../../../../../../shared/utils/runtime-settings.js'
import {
	defaultVueformBuilderFormat,
	schemaHasPreferencesWithUnassignedChannelOrStatement,
	schemaContainsPii,
	schemaHasUnassignedHeaderOrFooter
} from '../../../../../../shared/utils/vueform-builder-utils.js'
import { AUTHENTICATION_TYPES } from './preference-page-authentication-types.js'
import parseJsonMixin from '../../../../../../shared/mixins/parse-json.js'
export default {
	components: {
		ValidationForm,
		StepperItems,
		Stepper,
		ConsentCollectionDetails,
		BuildStep,
		ThemeStep,
		ConsentCollectionLayout,
		ConfigurePreferencePage,
		DeployPreferencePage,
		OutcomesStep,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		ConfirmThemeManageModal,
		NonFormUnsavedChangesModal
	},
	mixins: [parseJsonMixin],
	beforeRouteLeave (to, from, next) {
		if (this.showUnsavedModal) {
			this.nextFunction = next
			this.showUnsavedChangesModal = true
			return
		}
		next()
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		accessedViaHub: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		const isFormBuilderScriptLoaded = Array.from(document.getElementsByTagName('script')).some(script => script.src === getEnv('VUE_APP_FORMBUILDER_URL'))
		if (!isFormBuilderScriptLoaded) {
			const formBuilderScript = document.createElement('script')
			formBuilderScript.src = getEnv('VUE_APP_FORMBUILDER_URL')
			document.head.appendChild(formBuilderScript)
		}
		return {
			defaultBrandInSelect,
			showSnackbar
		}
	},
	data () {
		const DETAILS_STEP = 0
		const BUILDER_STEP = 1
		const STYLE_STEP = 2
		const COMPLETE_STATUS = 5
		const outcomes = {
			URL_OUTCOME: 1,
			PAGE_OUTCOME: 2,
			REMAIN_ON_PAGE_OUTCOME: 3, // TBC
			FAILURE_URL: 'Forward To URL'
		}
		return {
			AUTHENTICATION_TYPES,
			outcomes,
			steps: [
				{
					title: 'Details',
					slot: 0,
					stepNumber: 0,
					stepComplete: false
				},
				{
					title: 'Build',
					slot: 1,
					stepNumber: 1,
					stepComplete: false
				},
				{
					title: 'Style',
					slot: 2,
					stepNumber: 2,
					stepComplete: false
				},
				{
					title: 'Configure',
					slot: 3,
					stepNumber: 3,
					stepComplete: false
				},
				{
					title: 'Outcomes',
					slot: 4,
					stepNumber: 4,
					stepComplete: false
				},
				{
					title: 'Deploy',
					slot: 5,
					stepNumber: 5,
					stepComplete: false
				}
			],
			step: 0,
			preferencePage: {
				status: 0,
				name: '',
				description: '',
				customDefaultLanguageId: 0,
				linkText: null,
				isEnabled: true,
				brandId: defaultBrandInSelect.value,
				customPrivacyPolicies: [],
				customErrorUrl: null,
				authenticationDetails: {
					authTypeId: null,
					standDakAuthField: null,
					standDakAuthFieldFriendlyName: null,
					verificationSentMessage: null,
					verificationAreaText: null,
					verificationFailedMessage: null,
					proveConfirmationEmailId: null,
					themeId: null
				},
				linkFields: [
					{
						fieldName: '',
						replaceValueInLink: '',
						previewValue: '',
						isEncrypted: false
					}
				],
				propertyFields: {
					sourceCodeReplaceValue: '%SourceCode%',
					responseCodeReplaceValue: '%ResponseChannel%'
				},
				configurationOptions: {
					createStanddakIfNoMatch: false,
					multiStandDakMatchIsFailure: false,
					ipLimitations: {
						limitSubmissionsByIpTypeId: false,
						noOfSubmissionsAllowedFromIp: 0,
						ipLimitPeriodInMins: 0
					},
					encryption: {
						encryptionKeyId: null
					},
					successOutcome: {
						confirmationPageTypeId: null,
						internalConfirmationPageId: null,
						externalConfirmationPageUrl: null
					}
				},
				formBuilderSchemaGUID: null,
				formBuilderThemeGUID: null,
				pageSettings: {
					hideCurrentPrefData: false
				}
			},
			details: {
				name: '',
				description: '',
				brandId: null,
				customDefaultLanguageId: 0
			},
			lookupData: {},
			embedCode: '',
			prefPageId: null,
			DETAILS_STEP,
			BUILDER_STEP,
			STYLE_STEP,
			COMPLETE_STATUS,
			channels: [],
			headers: [],
			footers: [],
			extendedPreferences: [],
			themes: [],
			themeToManage: null,
			formHasPii: false,
			formSaved: false,
			showUnsavedChangesModal: false,
			nextFunction: null,
			displayBuilder: false,
			displayPage: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return !!this.id && this.completePreferencePage
		},
		isPartialOrCompletePreferencePage () {
			return !!this.prefPageId || !!this.id
		},
		completePreferencePage () {
			return this.preferencePage.status === 5
		},
		userFullPermissions () {
			if (!this.isEdit) return true
			else return (this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)) && this.userHasBrand
		},
		formHasPiiFields () {
			return this.formHasPii
		},
		userHasBrand () {
			const brands = brandOptions.value.map(({ value }) => value)
			return brands.includes(this.preferencePage.brandId)
		},
		stepCaption () {
			switch (this.step) {
				case 0:
					return 'The administration details for your drag-and-drop preference page'
				case 1:
					return 'Build your drag-and-drop preference page'
				case 2:
					return 'Style your drag-and-drop preference page'
				case 3:
					return 'Configure your drag-and-drop preference page'
				case 4:
					return 'Set the success and failure messages for your customers'
				case 5:
					return 'Collect the code for your drag-and-drop preference page'
				default:
					return ''
			}
		},
		showNextButton () {
			if (this.isEdit) {
				return this.step < this.steps.length - 1
			} else {
				return this.step < this.steps.length - 2
			}
		},
		showPreviewButton () {
			return !!this.preferencePage.linkFields.find(field => field.previewValue)
		},
		schemaChanged () {
			const formHistory = JSON.parse(localStorage.getItem('vueform-history'))
			return formHistory?.length > 1
		},
		themeChanged () {
			const formHistory = JSON.parse(localStorage.getItem('vueform-history'))
			const mostRecentChange = formHistory?.[formHistory.length - 1]?.theme
			const secondToLastChange = formHistory?.[formHistory.length - 2]?.theme
			const themeChanged = !isEqual(mostRecentChange, secondToLastChange)
			return themeChanged
		},
		showUnsavedModal () {
			if (this.step === this.BUILDER_STEP) {
				return this.schemaChanged && !this.formSaved
			} else if (this.step === this.STYLE_STEP) {
				return this.themeChanged && !this.formSaved
			} else return false
		}
	},
	async mounted () {
		const { lookUpData, languageData } = await this.fetchData()
		this.setLookupData(lookUpData, languageData)
		if (this.isPartialOrCompletePreferencePage) {
			await this.fetchExistingData()
		} else {
			localStorage.setItem('vueform-builder', JSON.stringify(defaultVueformBuilderFormat))
		}
		this.setDetails()
		await this.getAllThemes()
		await this.getBuilderData()
		this.displayPage = true
		this.displayBuilder = true
	},
	methods: {
		setLookupData (lookupData, languageData) {
			this.lookupData = {
				authenticationItems: lookupData.linkAuthTypes,
				proveConfirmationEmails: this.dropdownMap(lookupData.proveConfirmationEmails, 'emailId', 'emailName'),
				availableFields: this.dropdownMap(lookupData.contactFields, 'id', 'fieldName').filter(({ text }) => text !== 'CRC'),
				encryptionKeys: this.dropdownMap(lookupData.encryptionKeys, 'encryptionKeyId', 'encryptionKeyName'),
				languages: this.dropdownMap(languageData, 'languageID', 'languageName'),
				confirmationPages: lookupData.confirmationPages
			}
		},
		async fetchData () {
			const [
				{ data: { lookUpData } }, { data }
			] = await Promise.all([
				getLinks(), getAssignedLanguages()
			])
			return { lookUpData, languageData: data }
		},
		async fetchExistingData () {
			// Fetch the preference page data
			const { preferencePage } = await getPreferencePage(this.id)
			this.preferencePage = preferencePage
			this.embedCode = preferencePage.embedLink
			this.prefPageId = this.id
			const builderConfig = { ...defaultVueformBuilderFormat }
			if (preferencePage.formBuilderSchemaGUID) {
				const { data } = await getSchema(preferencePage.formBuilderSchemaGUID)
				builderConfig.schema = JSON.parse(data.json)
			}
			if (preferencePage.formBuilderThemeGUID) {
				const { data } = await getTheme(preferencePage.formBuilderThemeGUID)
				builderConfig.theme = JSON.parse(data.json)
				builderConfig.builder.themeName = data.themeName
			}
			if (this.preferencePage.status === this.COMPLETE_STATUS) {
				this.setAllStepsToComplete()
			} else {
				this.step = this.preferencePage.status
				this.steps.forEach((step, index) => {
					if (index <= this.preferencePage.status) {
						step.stepComplete = true
					}
				})
			}
			localStorage.setItem('vueform-builder', JSON.stringify(builderConfig))
		},
		setDetails () {
			this.details = {
				name: this.preferencePage.name,
				description: this.preferencePage.description,
				brandId: this.preferencePage.brandId ?? defaultBrandInSelect.value,
				customDefaultLanguageId: this.preferencePage.customDefaultLanguageId
			}
		},
		async getBuilderData () {
			const [
				{ data: headers },
				{ data: footers },
				{ data: { channels } },
				{ extendedPreferences }
			] = await Promise.all([
				getHeaders(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getPreferenceChannels(),
				getExtendedPreferences()
			])
			this.headers = headers
			this.footers = footers
			this.channels = channels
			this.extendedPreferences = extendedPreferences
		},
		async getAllThemes () {
			const { themes } = await getAllThemes()
			this.themes = themes.sort((a, b) => a.themeName.localeCompare(b.themeName))
			const themesObject = {}
			this.themes.forEach(theme => {
				const themeProperties = {
					...this.parsedJSON(theme.json)
				}
				delete themeProperties.themeId
				themesObject[theme.themeName] = {
					themeId: theme.themeId,
					...themeProperties
				}
			})
			localStorage.setItem('vueform-themes', JSON.stringify(themesObject))
		},
		dropdownMap (items, key, value) {
			return items.map(item => ({
				...item,
				text: item[value],
				value: item[key]
			}))
		},
		async onReloadLookupData () {
			const { lookUpData, languageData } = await this.fetchData()
			this.setLookupData(lookUpData, languageData)
		},
		setAllStepsToComplete () {
			this.steps.forEach(step => {
				step.stepComplete = true
			})
		},
		async nextStep () {
			let progressToNextStep = true
			if (this.userFullPermissions) {
				progressToNextStep = await this.savePreferencePage(true)
				if (this.preferencePage.status <= this.step) {
					this.preferencePage.status = this.step
				}
			}
			if (!this.themeToManage && progressToNextStep !== false) {
				this.steps[this.step].stepComplete = true
				this.step++
			}
		},
		async previousStep () {
			if (this.userFullPermissions) {
				await this.savePreferencePage(false, true)
			}
			if (!this.themeToManage) {
				this.step--
			}
		},
		onUpdateDetails (property, value) {
			this.details = {
				...this.details,
				[property]: value
			}
			this.preferencePage = {
				...this.preferencePage,
				[property]: value
			}
		},
		backToOverview () {
			if (this.accessedViaHub) {
				this.$router.push({ name: CONSENT_COLLECTION })
			} else {
				this.$router.push({ name: CONSENT_COLLECTION_PREFERENCE_PAGES })
			}
			localStorage.removeItem('vueform-builder')
			localStorage.removeItem('vueform-history')
			localStorage.removeItem('vueform-themes')
		},
		manageThemeFunctionality (accessedViaNext = false, accessedViaPrevious = false) {
			const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
			const currentTheme = JSON.parse(JSON.stringify(builderConfig.theme))
			const themeName = builderConfig.builder.themeName
			const newTheme = !this.isEdit && !themeName
			const themeToManage = {
				brandId: this.preferencePage.brandId,
				accessedViaNext,
				accessedViaPrevious,
				variables: JSON.stringify(currentTheme)
			}
			if (newTheme) {
				this.themeToManage = {
					...themeToManage,
					createFromExisting: false
				}
			} else {
				this.handleDirtyTheme(themeToManage, currentTheme, themeName, accessedViaNext, accessedViaPrevious)
			}
		},
		handleDirtyTheme (themeToManage, currentTheme, themeName, accessedViaNext, accessedViaPrevious) {
			const existingTheme = JSON.parse(localStorage.getItem('vueform-themes'))[themeName]
			const themeId = existingTheme?.themeId
			delete existingTheme.themeId
			delete currentTheme.themeId

			const borderColorSignatureHr = 'border-color-signature-hr'
			delete existingTheme[borderColorSignatureHr]
			delete currentTheme[borderColorSignatureHr]

			const dirtyTheme = !isEqual(existingTheme, currentTheme)
			if (dirtyTheme) {
				this.themeToManage = {
					...themeToManage,
					createFromExisting: true,
					themeName: themeName,
					themeId: themeId
				}
			} else {
				this.preferencePage.formBuilderThemeGUID = themeId
				this.putPreferencePage(accessedViaNext, accessedViaPrevious)
			}
		},
		async manageTheme (themeGuid, accessedViaNext, accessedViaPrevious, themeName) {
			this.preferencePage.formBuilderThemeGUID = themeGuid
			this.themeToManage = null
			const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
			builderConfig.builder.themeName = themeName
			localStorage.setItem('vueform-builder', JSON.stringify(builderConfig))
			this.getAllThemes()
			this.putPreferencePage(accessedViaNext, accessedViaPrevious)
			if (accessedViaNext) {
				this.steps[this.step].stepComplete = true
				this.step++
			} else if (accessedViaPrevious) {
				this.step--
			}
		},
		async saveSchema () {
			const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
			const builderConfigContainsPii = schemaContainsPii(builderConfig.schema)
			this.formHasPii = builderConfigContainsPii
			if (this.preferencePage.authenticationDetails.authTypeId === AUTHENTICATION_TYPES.DIRECT && this.preferencePage.status >= 4 && builderConfigContainsPii) {
				return false
			}

			const schema = {
				brandId: this.preferencePage.brandId,
				isTemplate: false,
				json: JSON.stringify(builderConfig.schema)
			}
			if (!this.preferencePage.formBuilderSchemaGUID) {
				await postSchema(schema).then(response => {
					this.preferencePage.formBuilderSchemaGUID = response.data.id
				})
			} else {
				delete schema.brandId
				await putSchema(this.preferencePage.formBuilderSchemaGUID, schema)
			}

			return true
		},
		async savePreferencePage (accessedViaNext = false, accessedViaPrevious = false) {
			if (this.step === this.DETAILS_STEP && !this.isPartialOrCompletePreferencePage) {
				await this.postPreferencePage(accessedViaNext)
			} else {
				const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
				if (this.step === this.BUILDER_STEP && this.userFullPermissions) {
					const schema = builderConfig.schema
					if (Object.keys(schema).length === 0) {
						this.showSnackbar({ text: 'Please build the form', color: 'red' })
						return false
					}
					const hasPrefsWithUnassignedChannelOrStatement = schemaHasPreferencesWithUnassignedChannelOrStatement(schema)

					if (hasPrefsWithUnassignedChannelOrStatement) {
						this.showSnackbar({ text: 'One or more Preference components have unassigned Channels/Statements.', color: 'red' })
						return false
					}

					const hasUnassignedHeaderOrFooter = schemaHasUnassignedHeaderOrFooter(schema)
					if (hasUnassignedHeaderOrFooter) {
						this.showSnackbar({ text: 'One or more Header/Footer components have not been assigned.', color: 'red' })
						return false
					}

					if (this.isEdit) {
						const result = await this.saveSchema()
						if (!result) {
							this.showSnackbar({
								text: 'Direct authentication cannot be used if any PII is used on the Preference Page',
								color: 'red'
							})
							return false
						}
					} else {
						await this.saveSchema()
					}
				}
				if (this.step === this.STYLE_STEP && this.userFullPermissions) {
					await this.manageThemeFunctionality(accessedViaNext, accessedViaPrevious)
				} else if (this.step !== this.STYLE_STEP && this.userFullPermissions) {
					await this.putPreferencePage(accessedViaNext, accessedViaPrevious)
				}
				this.formSaved = true
				return true
			}
		},
		async postPreferencePage (accessedViaNext = false) {
			const payload = {
				pageName: this.preferencePage.name,
				pageDescription: this.preferencePage.description,
				brandId: this.preferencePage.brandId,
				customDefaultLanguageId: this.preferencePage.customDefaultLanguageId,
				status: accessedViaNext ? 1 : 0
			}
			await createPreferencePage(payload).then(response => {
				this.prefPageId = response.data.preferencePageId
				this.showSnackbar('Preference Page Created.')
			})
		},
		async putPreferencePage (accessedViaNext, accessedViaPrevious) {
			await updatePreferencePage(this.prefPageId, this.formatPreferencePagePayload(accessedViaNext, accessedViaPrevious)).then(response => {
				this.embedCode = response.data.embedLink
				this.showSnackbar('Preference Page updated successfully')
			})
		},
		formatPreferencePagePayload (accessedViaNext, accessedViaPrevious) {
			if (!this.preferencePage.customPrivacyPoliciesEnabled) {
				this.preferencePage.customPrivacyPolicies = []
			}
			const preferencePage = {
				...this.preferencePage,
				pageName: this.preferencePage.name,
				pageDescription: this.preferencePage.description,
				authenticationDetails: this.formatAuthenticationDetails(),
				configurationOptions: {
					...this.preferencePage.configurationOptions,
					successOutcome: {
						...this.preferencePage.configurationOptions.successOutcome,
						internalConfirmationPageId: this.preferencePage.configurationOptions.successOutcome.confirmationPageTypeId === this.outcomes.PAGE_OUTCOME
							? this.preferencePage.configurationOptions.successOutcome.internalConfirmationPageId
							: null,
						externalConfirmationPageUrl: this.preferencePage.configurationOptions.successOutcome.confirmationPageTypeId === this.outcomes.URL_OUTCOME
							? this.preferencePage.configurationOptions.successOutcome.externalConfirmationPageUrl
							: null
					},
					ipLimitations: {
						...this.preferencePage.configurationOptions.ipLimitations,
						limitSubmissionsByIpTypeId: this.preferencePage.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId ? 2 : 1
					}
				},
				status: this.setPutStatus(accessedViaNext, accessedViaPrevious)
			}
			this.preferencePage.status = preferencePage.status
			delete preferencePage.name
			delete preferencePage.description
			delete preferencePage.customPrivacyPoliciesEnabled
			if (this.isEdit) {
				delete preferencePage.createdBy
				delete preferencePage.createdByName
				delete preferencePage.createdDate
				delete preferencePage.lastUpdatedBy
				delete preferencePage.lastUpdatedByName
				delete preferencePage.lastUpdatedDate
			}
			return preferencePage
		},
		setPutStatus (accessedViaNext, accessedViaPrevious) {
			if (accessedViaNext) {
				if (this.step === 4 || this.preferencePage.status === this.COMPLETE_STATUS) {
					return this.COMPLETE_STATUS
				} else if (this.preferencePage.status <= this.step) {
					return this.step + 1
				} else {
					return this.preferencePage.status
				}
			} else if (accessedViaPrevious) {
				return this.preferencePage.status
			} else {
				return this.step === 4 || this.preferencePage.status === this.COMPLETE_STATUS ? this.COMPLETE_STATUS : this.step
			}
		},
		formatAuthenticationDetails () {
			const { authenticationDetails } = this.preferencePage
			return {
				authTypeId: authenticationDetails.authTypeId,
				standDakAuthField: authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.CONFIRM_VALUE
					? authenticationDetails.standDakAuthField
					: null,
				standDakAuthFieldFriendlyName: authenticationDetails.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? authenticationDetails.standDakAuthFieldFriendlyName
					: null,
				verificationSentMessage: authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL ||
					authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL
					? authenticationDetails.verificationSentMessage
					: null,
				verificationAreaText: authenticationDetails.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? authenticationDetails.verificationAreaText
					: null,
				verificationFailedMessage: authenticationDetails.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? authenticationDetails.verificationFailedMessage
					: null,
				proveConfirmationEmailId: authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL ||
					authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL
					? authenticationDetails.proveConfirmationEmailId
					: null,
				themeId: authenticationDetails.authTypeId !== AUTHENTICATION_TYPES.DIRECT ? authenticationDetails.themeId : null
			}
		},
		proceedFromUnsavedChanges () {
			localStorage.removeItem('vueform-builder')
			localStorage.removeItem('vueform-history')
			localStorage.removeItem('vueform-themes')
			this.showUnsavedChangesModal = false
		}
	}
}
</script>
